.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    /* background-color: #000; */
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
}

.flamer {
  position: relative;
  top: -12px;
  left: -12px;
  font-size: 24px;
}